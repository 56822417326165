let headerNav = [{
	name: "设备管理",
	path: "/device",
},
// {
// 	name: "项目管理",
// 	path: "/item",
// },
{
	name: "地图管理",
	path: "/map",
},
{
	name: "数据字典",
	path: "/dictionary",
},
// {
// 	name: '账号管理',
// 	path: '/permision'
// },
{
	name: '系统管理',
	path: '/system'
},
];

let sidebarList = [
	[{
		name: "设备管理",
		path: "/diviceList",
		parent: '设备管理'
	},
	{
		name: "设备仿真",
		path: "/diviceWarn",
		parent: '设备管理'
	},
	{
		name: "设备导入",
		path: "/deviceImport",
		parent: '设备管理'
	},
	],
	// [
	// 	{
	// 		name: "项目管理",
	// 		path: "/itemList",
	// 		parent: '项目管理'
	// 	},
	// ],
	[
		{
			name: "地图管理",
			path: "/maps",
			parent: '地图管理'
		},
	],

	[
		{
			name: "数据字典",
			path: "/dictionaryList",
			parent: '数据字典'
		},

		{
			name: "分组管理",
			path: "/groupList",
			parent: '数据字典'
		},
		{
			name: "视频管理",
			path: "/videoGroupList",
			parent: '数据字典'
		},

	],
	// [{
	// 	name: "员工列表",
	// 	path: "/yuangongList",
	// 	parent: '员工管理'
	// }, {
	// 	name: "角色列表",
	// 	path: "/jueseList",
	// 	parent: '员工管理'
	// },],

	[
		{
			name: "日志",
			path: "/log",
			parent: '系统管理'
		},
		{
			name: "设备同步",
			path: "/deviceSync",
			parent: '系统管理'
		},
	],

];
let sidebarList2 = [
	[{
		name: "设备管理",
		path: "/diviceList",
		parent: '设备管理'
	},
	{
		name: "设备仿真",
		path: "/diviceWarn",
		parent: '设备管理'
	},
	{
		name: "设备导入",
		path: "/deviceImport",
		parent: '设备管理'
	},
	],
	[
		{
			name: "地图管理",
			path: "/maps",
			parent: '地图管理'
		},
	],
	[
		{
			name: "数据字典",
			path: "/dictionary",
			parent: '数据字典'
		},
		{
			name: "分组管理",
			path: "/groupList",
			parent: '数据字典'
		},
		{
			name: "视频管理",
			path: "/videoGroupList",
			parent: '数据字典'
		},
	],
	[
		{
			name: "日志",
			path: "/log",
			parent: '系统管理'
		},
		{
			name: "设备同步",
			path: "/deviceSync",
			parent: '系统管理'
		},
	],
];
export {
	headerNav,
	sidebarList,
	sidebarList2
};
