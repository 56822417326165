<template>
	<div class="left-bar">
		<a-menu mode="inline" theme="dark" :selectedKeys="[curKey]">
			<a-sub-menu :key="menu.path" v-for="(menu, index) in headerNav">
				<!-- <span slot="title">
					<img src="@/assets/menu.png">
				</span> -->
				<template #icon><img src="@/assets/menu.png"></template>
				<template #title>
					<img v-if="index == 0" src="@/assets/device.png" style="margin-right: 10px" width="18px"
						height="18px">
					<img v-if="index == 2" src="@/assets/item.png" style="margin-right: 10px" width="18px"
						height="18px">
					<img v-if="index == 1" src="@/assets/map.png" style="margin-right: 10px" width="18px" height="18px">
					<img v-if="index == 3" src="@/assets/dictionary.png" style="margin-right: 10px" width="18px"
						height="18px">
					<img v-if="index == 4" src="@/assets/account.png" style="margin-right: 10px" width="18px"
						height="18px">
					{{ menu.name }}
				</template>
				<a-menu-item :key="item.path" v-for="item in curLeftMenu[index]">
					<router-link :to="item.path">{{ item.name }}</router-link>
				</a-menu-item>
			</a-sub-menu>
		</a-menu>
	</div>
</template>

<script>
import { Icon } from 'ant-design-vue'
const MyIcon = Icon.createFromIconfontCN({
	scriptUrl: '//at.alicdn.com/t/c/font_3676972_1ugw0jc888.js', // 在 iconfont.cn 上生成
})
export default {
	name: 'sidebar',
	data() {
		return {
		};
	},
	computed: {
		curKey() {
			return this.$route.fullPath
		},
		curLeftMenu() {
			let a = -1;
			let par = this.$route.meta.parent
			console.log('par::', par)
			let sidebarList = this.$store.state.sidebarList
			console.log('curLeftMenu::', sidebarList)
			return sidebarList
		},
		headerNav() {
			return this.$store.state.headerNav
		}
	},
	comments: {
		'my-icon': MyIcon,
	},
	watch: {

	},
	created() {
	},
	mounted() { },
	methods: {}
};
</script>

<style lang="less">
.left-bar {
	position: fixed;
	left: 0;
	top: 50px;
	bottom: 0;
	width: 180px;
	background: #1B2028;
	box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.12);
	z-index: 1;
}
</style>
