<template>
	<div class="app-container">
		<appHeader />
		<sidebar />
		<div class="app-body">
			<div class="app-wrapper">
				<router-view />
			</div>
		</div>
	</div>
</template>
<script>
import appHeader from "./header.vue";
import sidebar from './sidebar.vue'
export default {
	components: {
		appHeader,
		sidebar
	},
	data() {
		return {}
	}
}
</script>
<style lang="less">
.app-body {
	padding: 60px 10px 10px 190px;
	background-color: #EAEDF0;

	.app-wrapper {
		background: linear-gradient(226deg, #FFFFFF 0%, #FCFCFC 100%);
		min-height: calc(~"100vh - 84px");
		border-radius: 5px;
		padding: 18px;
	}
}
</style>