import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
export const routes = [
	// {
	// 	path: '/companyInfo',
	// 	component: () => import('@/view/company/companyInfo'),
	// 	name: 'CompanyInfo',
	// 	meta: {
	// 		title: '公司介绍',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '公司管理'
	// 	}
	// },
	// {
	// 	path: '/companyBrief',
	// 	component: () => import('@/view/company/companyBrief'),
	// 	name: 'CompanyBrief',
	// 	meta: {
	// 		title: '公司简介',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '公司管理'
	// 	}
	// },
	// {
	// 	path: '/qualificationList',
	// 	component: () => import('@/view/company/qualificationList'),
	// 	name: 'QualificationList',
	// 	meta: {
	// 		title: '资质列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '公司管理'
	// 	}
	// },
	// {
	// 	path: '/qualificationEdit',
	// 	component: () => import('@/view/company/qualificationEdit'),
	// 	name: 'QualificationEdit',
	// 	meta: {
	// 		title: '资质修改',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '公司管理'
	// 	}
	// },
	// {
	// 	path: '/videoList',
	// 	component: () => import('@/view/company/videoList'),
	// 	name: 'VideoList',
	// 	meta: {
	// 		title: '视频列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '公司管理'
	// 	}
	// },
	// {
	// 	path: '/videoEdit',
	// 	component: () => import('@/view/company/videoEdit'),
	// 	name: 'VideoEdit',
	// 	meta: {
	// 		title: '视频修改',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '公司管理'
	// 	}
	// },




	{
		path: '/diviceList',
		component: () => import('@/view/device/deviceList'),
		name: 'DeviceList',
		meta: {
			title: '设备管理',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/diviceWarn',
		component: () => import('@/view/device/deviceWarn'),
		name: 'DeviceWarn',
		meta: {
			title: '设备仿真',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},
	{
		path: '/deviceImport',
		component: () => import('@/view/device/deviceImport'),
		name: 'DeviceImport',
		meta: {
			title: '设备导入',
			noCache: true,
			permission: '',
			parent: '设备管理'
		}
	},

	// {
	// 	path: '/itemList',
	// 	component: () => import('@/view/item/itemList'),
	// 	name: 'ItemList',
	// 	meta: {
	// 		title: '项目管理',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '项目管理'
	// 	}
	// },
	{
		path: '/maps',
		component: () => import('@/view/map/maps'),
		name: 'Maps',
		meta: {
			title: '地图管理',
			noCache: true,
			permission: '',
			parent: '地图管理'
		}
	},
	{
		path: '/dictionaryList',
		component: () => import('@/view/dictionary/dictionaryList'),
		name: 'DictionaryList',
		meta: {
			title: '数据字典',
			noCache: true,
			permission: '',
			parent: '数据字典'
		}
	},
	{
		path: '/groupList',
		component: () => import('@/view/dictionary/groupList'),
		name: 'GroupList',
		meta: {
			title: '分组管理',
			noCache: true,
			permission: '',
			parent: '数据字典'
		}
	},
	{
		path: '/videoGroupList',
		component: () => import('@/view/dictionary/videoGroupList'),
		name: 'VideoGroupList',
		meta: {
			title: '视频分组',
			noCache: true,
			permission: '',
			parent: '数据字典'
		}
	},

	{
		path: '/log',
		component: () => import('@/view/system/log'),
		name: 'Log',
		meta: {
			title: '日志',
			noCache: true,
			permission: '',
			parent: '系统管理'
		}
	},

	{
		path: '/deviceSync',
		component: () => import('@/view/system/deviceSync'),
		name: 'DeviceSync',
		meta: {
			title: '设备同步',
			noCache: true,
			permission: '',
			parent: '系统管理'
		}
	},
	// {
	// 	path: '/deviceEdit',
	// 	component: () => import('@/view/device/deviceEdit'),
	// 	name: 'DeviceList',
	// 	meta: {
	// 		title: '设备编辑',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },


	// {
	// 	path: '/customerList',
	// 	component: () => import('@/view/device/customerList'),
	// 	name: 'CustomerList',
	// 	meta: {
	// 		title: '客户列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },
	// {
	// 	path: '/customerEdit',
	// 	component: () => import('@/view/device/customerEdit'),
	// 	name: 'CustomerEdit',
	// 	meta: {
	// 		title: '客户编辑',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },


	// {
	// 	path: '/leaseCustomerList',
	// 	component: () => import('@/view/device/leaseCustomerList'),
	// 	name: 'LeaseCustomerList',
	// 	meta: {
	// 		title: '租赁客户',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },
	// {
	// 	path: '/eqCusEdit',
	// 	component: () => import('@/view/device/equipmentCustomerEdit'),
	// 	name: 'eqCusEdit',
	// 	meta: {
	// 		title: '客户设备编辑',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },

	// {
	// 	path: '/propertyCustomerList',
	// 	component: () => import('@/view/device/propertyCustomerList'),
	// 	name: 'PropertyCustomerList',
	// 	meta: {
	// 		title: '产权客户',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },
	// {
	// 	path: '/changeList',
	// 	component: () => import('@/view/device/changeList'),
	// 	name: 'ChangeList',
	// 	meta: {
	// 		title: '变更记录',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },
	// {
	// 	path: '/changeEdit',
	// 	component: () => import('@/view/device/changeEdit'),
	// 	name: 'ChangeEdit',
	// 	meta: {
	// 		title: '设备变更',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },
	// {
	// 	path: '/contrastList',
	// 	component: () => import('@/view/device/contrastList'),
	// 	name: 'ContrastList',
	// 	meta: {
	// 		title: '对比记录',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },
	// {
	// 	path: '/customerListDivide',
	// 	component: () => import('@/view/device/customerListDivide'),
	// 	name: 'CustomerListDivide',
	// 	meta: {
	// 		title: '客户分配',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '设备管理'
	// 	}
	// },
];

export const adminRoutes = [
	// {
	// 	path: '/jueseList',
	// 	component: () => import('@/view/permision/jueseList'),
	// 	name: 'jueseList',
	// 	meta: {
	// 		title: '角色列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '员工管理'
	// 	}
	// },
	// {
	// 	path: '/yuangongList',
	// 	component: () => import('@/view/permision/yuangongList'),
	// 	name: 'YuangongList',
	// 	meta: {
	// 		title: '员工列表',
	// 		noCache: true,
	// 		permission: '',
	// 		parent: '员工管理'
	// 	}
	// }
];
const index = {
	path: '/index',
	component: () => import('@/view/index/index'),
	name: 'Index',
	meta: {
		title: '大理卷烟厂IOC管理后台',
		noCache: true,
		permission: '',
		parent: '首页'
	}
};
const threeRoutes = [];

export const initRoutes = [
	{
		path: '/',
		component: Layout,
		redirect: '/index',
		name: 'Index',
		meta: {
			title: '大理卷烟厂IOC管理后台',
			icon: 'location'
		},
		children: [index, ...routes, ...adminRoutes]
	},
	{
		path: '/login',
		component: () => import('@/view/login'),
		name: 'Login',
		meta: {
			title: '登录'
		}
	},
	{
		path: '/mapsFull',
		component: () => import('@/view/map/mapsFull'),
		name: 'mapsFull',
		meta: {
			title: '全屏打点'
		}
	},
	{
		path: '/404',
		component: () => import('@/view/404'),
		name: '404',
		meta: {
			title: '页面未找到'
		}
	},
	{
		path: '/401',
		component: () => import('@/view/401'),
		name: '401',
		meta: {
			title: '暂无权限'
		}
	},
	// {
	// 	path: '/aboutInfo',
	// 	component: () => import('@/view/aboutInfo'),
	// 	name: 'AboutInfo',
	// 	meta: {
	// 		title: '关于我们'
	// 	}
	// },
	// {
	// 	path: '/webview',
	// 	component: () => import('@/view/webview'),
	// 	name: 'Webview',
	// 	meta: {
	// 		title: '隐私服务'
	// 	}
	// },
	// {
	// 	path: '/policyInfo',
	// 	component: () => import('@/view/policyInfo'),
	// 	name: 'PolicyInfo',
	// 	meta: {
	// 		title: '政策详情'
	// 	}
	// }
];
const createRouter = () =>
	new Router({
		mode: 'history', // require service support
		base: '/',
		scrollBehavior(to, from, savedPosition) {
			console.log('路由滚动', savedPosition);
			// return new Promise((resolve, reject) => {
			//     setTimeout(() => {
			// 		let a = {}
			// 		if(savedPosition) {
			// 			a = savedPosition
			// 		}else{
			// 			a =  {
			// 				x: 0,
			// 				y: 0
			// 			}
			// 		}
			//       resolve(a)
			//     }, 200)
			//   })
			// let a = {}
			// if(savedPosition) {
			// 	a = savedPosition
			// }else{
			// 	a =  {
			// 		x: 0,
			// 		y: 0
			// 	}
			// }
			// return a
		},
		routes: initRoutes
	});

const router = createRouter();

export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}
export default router;
