<template>
	<div class="header">
		<div class="logo">
			<img src="@/static/images/logo_3.png">
			<div class="logo-text">
				大理卷烟厂IOC管理后台
			</div>
		</div>
		<ul>
			<!-- <li v-for="(item,index) in headerNav" :class="{act : curPar == item.name}" :key="index">
				<router-link class="item" :to="sidebarList[index][0].path">{{item.name}}</router-link>
			</li> -->
		</ul>
		<div class="user-info-box">
			<a-dropdown>
				<a-menu slot="overlay" @click="handleMenuClick">
					<a-menu-item key="1">
						<a-button style="width: 100%" type="primary" size="small">修改密码</a-button>
					</a-menu-item>
					<a-menu-item key="2">
						<a-button style="width: 100%" type="danger" size="small">退出登录</a-button>
					</a-menu-item>
				</a-menu>
				<a-button class="dropdown-btn" style="margin-left: 8px">你好！{{ userInfo.name }}
					<a-icon type="user" />
				</a-button>
			</a-dropdown>

		</div>
		<a-modal ok-text="提交" cancel-text="取消" title="修改密码" :visible="visiblePass" @ok="submitPassword"
			:confirmLoading="confirmPassLoading" @cancel="cancelPassword">
			<a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" ref="passForm" :model="passInfo"
				:rules="passRules">
				<a-form-model-item prop="password" label="旧密码">
					<a-input v-model="passInfo.password"></a-input>
				</a-form-model-item>
				<a-form-model-item prop="newPassword" label="新密码">
					<a-input type="password" v-model="passInfo.newPassword"></a-input>
				</a-form-model-item>
				<a-form-model-item prop="rePassword" label="确认密码">
					<a-input type="password" v-model="passInfo.rePassword"></a-input>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
import {
	domain
} from '@/siteInfo.js'
export default {
	name: 'appHeader',
	data() {
		let reValidator = (rule, value, callback) => {
			if (!value) {
				callback(new Error("请输入确认密码"));
			} else if (value !== this.passInfo.newPassword) {
				callback(new Error("两次密码输入不一致"));
			} else {
				callback();
			}
		};
		return {
			navList: [],
			visiblePass: false,
			passInfo: {
				password: '',
				newPassword: '',
				rePassword: ''
			},
			confirmPassLoading: false,
			passRules: {
				password: [
					{
						required: true,
						message: "请输入原密码",
						trigger: "blur",
					}
				],
				newPassword: [
					{
						required: true,
						message: "请输入新密码",
						trigger: "blur",
					}
				],
				rePassword: [
					{
						validator: reValidator,
						required: true,
						trigger: "blur",
					}
				],
			},
		}
	},
	computed: {
		curPar() {
			return this.$route.meta.parent
		},
		sidebarList() {
			return this.$store.state.sidebarList
		},
		headerNav() {
			return this.$store.state.headerNav
		},
		userInfo() {
			return this.$store.state.userInfo
		}
	},
	created() {
	},
	methods: {
		logout() {
			this.$store.commit('logout')
			window.location.href = domain + "/admin/v1/authing/loginOut";
			// this.$router.push({ path: '/login' })
		},
		handleMenuClick(ind) {
			console.log(ind)
			if (ind.key == 1) {
				this.visiblePass = true
			} else {
				this.logout()
			}
		},
		cancelPassword() {
			this.initForm()
			this.visiblePass = false
		},
		submitPassword() {
			let t = this;
			this.$refs.passForm.validate((valid) => {
				if (valid) {
					this.confirmLoading = true;
					let obj = JSON.parse(JSON.stringify(this.passInfo))
					obj.user_id = this.userInfo.user_id
					this.$post('upPassword', obj).then((res) => {
						let { code, msg, data } = res;
						this.confirmLoading = false;
						if (code == 0) {
							this.visiblePass = false
							this.$message.success(msg, 1.5);
							this.initForm();
							this.logout()
						} else {
							this.$message.error(msg, 1.5);
						}
					}).catch((err) => {
						this.confirmLoading = false;
					});
				}
			});
		},
		initForm() {
			this.passInfo = {
				password: '',
				newPassword: '',
				rePassword: ''
			}
		}
	}
}; 
</script>

<style lang="less">
.header {
	background-color: #2B6AE0;
	height: 50px;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	padding-left: 200px;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 10px;

	.logo {
		position: absolute;
		left: 27px;
		top: 0;
		height: 50px;
		display: flex;
		align-items: center;

		.logo-text {
			line-height: 50px;
			color: #fff;
			font-size: 20px;
		}

		img {
			width: 30px;
			margin-right: 10px;
			height: auto;
		}

		.qiye {
			font-size: 16px;
			line-height: 64px;
			color: #fff;
		}
	}

	ul {
		display: flex;
		margin: 0;

		li {
			line-height: 64px;

			.item {
				display: block;
				padding: 0 27px;
				color: rgba(255, 255, 255, 0.4);
			}
		}

		.act {
			.item {
				color: #fff;
			}
		}
	}

	.dropdown-btn {
		background: none;
		color: #fff;
		border: none;
		box-shadow: none;

		&:hover {
			background: none;
		}
	}
}

@media screen and (max-width: 1280px) {
	.header {
		ul {
			li {
				.item {
					padding: 0 16px;
				}
			}
		}
	}
}
</style>
